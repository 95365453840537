<template>
  <div class="OeeCharts">
    <el-row :gutter="10">
      <el-col :span="2" class="btns">
        <el-button class="iconfont icon-dcdaochu1" @click="exportToImg">导出</el-button>
      </el-col>
      <el-col :offset="2" :span="3">
        <el-cascader :disabled="isfactory" clearable v-model="lineValue" placeholder="工厂结构" :props="Props" :options="lineTree" @change="LinehandleChange"></el-cascader>
      </el-col>
      <el-col :span="3">
        <el-select :disabled="isfactory" clearable @clear="clearDevice" @change="DeivcehandleChange" v-model="deviceId" placeholder="选择设备">
          <el-option v-for="item in DeviceOptions" :key="item.deviceId" :label="item.name" :value="item.deviceId"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select :disabled="isdeviceType" clearable @clear="isfactory = false" @change="DeivceTypehandleChange" v-model="deviceTypeId" placeholder="设备类型">
          <el-option v-for="item in DeviceTypeOptions" :key="item.deviceTypeId" :label="item.name" :value="item.deviceTypeId"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-model="type" placeholder="时段类型">
          <el-option v-for="item in DateType" :key="item.type" :label="item.name" :value="item.type"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <Timerpick :vModel="date" startPlaceholder="开始时间" endPlaceholder="结束时间" :clearable="false" :editable="false" :type="type" format="yyyy-MM-dd" />
      </el-col>
      <el-col :span="2" class="btns">
        <el-button icon="el-icon-search" @click="seach">查询</el-button>
      </el-col>
    </el-row>
    <baseSection name="效率趋势">
      <el-row>
        <el-col :offset="10" :span="4" v-if="chartsTitle">{{ chartsTitle + '的效率趋势图' }}</el-col>
        <el-col :offset="21" :span="3">
          <div class="switchBox" v-if="isCut">
            <span :title="title[0]" @click="change(1)" :class="ishistogram ? 'histogramactive' : 'histogram'"></span>
            <span :title="title[1]" @click="change(2)" :class="isline ? 'lineactive' : 'line'"></span>
            <span :title="title[2]" @click="change(3)" :class="iscurve ? 'curveactive' : 'curve'"></span>
          </div>
        </el-col>
      </el-row>
      <div class="chartsOee">
        <Histogram :ChartsData="ChartsData" v-if="ishistogram" />
        <Linecharts :ChartsData="ChartsData" v-if="isline" />
        <Curve :ChartsData="ChartsData" v-if="iscurve" />
      </div>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Timerpick from '@/components/TimerPick/index.vue';
import Histogram from './charts/histogram.vue';
import Linecharts from './charts/line.vue';
import Curve from './charts/curve.vue';
import domtoimage from 'dom-to-image';
import { frontOneHour } from '../until.js';
export default {
  components: {
    Timerpick,
    Histogram,
    Linecharts,
    Curve,
    baseSection
  },
  data() {
    return {
      chartsTitle: '',
      lineTree: [],
      lineValue: '',
      Props: {
        label: 'name',
        value: 'id',
        expandTrigger: 'hover',
        checkStrictly: true
      },
      DeviceOptions: [],
      deviceId: '',
      DeviceTypeOptions: [],
      deviceTypeId: '',
      paramsOptions: [],
      paramsId: '',
      date: [frontOneHour('yyyy-MM-dd', false), frontOneHour('yyyy-MM-dd', true)],
      DateType: [
        { name: '天', type: 'date' },
        { name: '周', type: 'week' },
        { name: '月', type: 'month' }
      ],
      type: 'date',
      ishistogram: false,
      isline: false,
      iscurve: false,
      ChartsData: null,
      isdeviceType: false,
      isfactory: false,
      title: ['柱状图', '曲线图', '曲线图'],
      isCut: false,
      treeList: []
    };
  },
  created() {
    this.init();
  },
  watch: {},
  methods: {
    exportToImg() {
      if (!this.ChartsData) {
        this.$message.warning('无数据，无法导出');
        return;
      }

      domtoimage
        .toPng(document.querySelector('.chartsOee'))
        .then(function (dataUrl) {
          let img = new Image();
          img.src = dataUrl;
          let a = document.createElement('a');
          a.setAttribute('href', dataUrl);
          a.setAttribute('download', '效率趋势.png');
          a.click();
        })
        .catch(function () {
          this.$message.error('无数据无法导出');
        });
    },
    async init() {
      const treeRes = (await this.$apis.FactoryChart.list()).map(item => {
        return {
          ...item,
          label: item.name
        };
      });
      this.lineTree = treeRes;
      this.lineValue = [this.lineTree[0].id];
      this.unflod(JSON.parse(JSON.stringify(treeRes)));
      const deviceTypeRes = await this.$apis.deviceTypeVirtual.deviceType();
      this.DeviceTypeOptions = deviceTypeRes;
      this.seach();
      this.LinehandleChange();
    },
    unflod(array) {
      array.forEach(item => {
        this.treeList.push(item);
        if (item.children && item.children.length) {
          this.unflod(item.children);
        }
      });
    },
    async LinehandleChange() {
      if (this.deviceId === '' && this.lineValue.length === 0) {
        this.isdeviceType = false;
      } else {
        this.isdeviceType = true;
      }
      // if (this.lineValue.length !== 3) {
      //   return false;
      // }
      this.DeviceOptions = [];
      this.deviceId = '';
      const data = {
        path: this.lineValue[this.lineValue.length - 1],
        currentPageSize: 500
      };
      const deviceRes = await this.$apis.LSDconfig.getdevice(data);
      this.DeviceOptions = deviceRes;
    },
    async DeivcehandleChange() {
      if (this.lineValue.length === 0 && this.deviceId === '') {
        this.isdeviceType = false;
      }
    },
    clearDevice() {
      if (this.lineValue.length === 0) {
        this.isdeviceType = false;
      }
    },
    DeivceTypehandleChange() {
      this.isfactory = true;
    },
    async seach() {
      this.ishistogram = true;
      this.isline = false;
      this.iscurve = false;
      const data = {
        deviceIds: this.deviceId,
        startDate: this.date[0],
        endDate: this.date[1],
        type: this.type === 'date' ? 'DAY' : this.type === 'week' ? 'WEEK' : 'MONTH',
        factoryStructIds: this.deviceId === '' ? this.lineValue[this.lineValue.length - 1] : '',
        deviceTypeIds: this.deviceTypeId
      };
      const res = await this.$apis.Oeecharts.get(data);
      if (!res) {
        return false;
      }
      for (const key in data) {
        const element = data[key];
        if (element) {
          switch (key) {
            case 'deviceIds':
              this.DeviceOptions.forEach(item => {
                if (item.deviceId === element) {
                  this.chartsTitle = item.name;
                }
              });
              break;
            case 'deviceTypeIds':
              this.DeviceTypeOptions.forEach(item => {
                if (item.deviceTypeId === element) {
                  this.chartsTitle = item.name;
                }
              });
              break;
            case 'factoryStructIds':
              this.treeList.forEach(item => {
                if (item.id === element) {
                  this.chartsTitle = item.name;
                }
              });
              break;
          }
        }
      }
      let performance = [];
      let availability = [];
      let quality = [];
      let oee = [];
      res.yaxisValues.forEach(item => {
        performance.push((item.performance * 100).toFixed(2));
        availability.push((item.availability * 100).toFixed(2));
        quality.push((item.quality * 100).toFixed(2));
        oee.push((item.oee * 100).toFixed(2));
      });
      this.ChartsData = {
        xAxisValues: res.xaxisValues,
        performance,
        availability,
        quality,
        oee
      };
      this.ishistogram = true;
      this.isCut = true;
    },
    change(data) {
      switch (data) {
        case 1:
          this.ishistogram = true;
          this.isline = false;
          this.iscurve = false;
          break;
        case 2:
          this.ishistogram = false;
          this.isline = true;
          this.iscurve = false;
          break;
        case 3:
          this.ishistogram = false;
          this.isline = false;
          this.iscurve = true;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.iconfont {
  line-height: 11px;
}
.el-row {
  margin-bottom: 20px;
}
.switchBox {
  display: flex;
  justify-content: space-around;
  width: 150px;
}
.histogram {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts01.png');
}
.histogramactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts01active.png');
}
.line {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts02.png');
}
.lineactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts02active.png');
}
.curve {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts03.png');
}
.curveactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts03active.png');
}
.el-cascader,
.el-select {
  width: 100%;
}
/deep/ .time-slelector {
  display: flex;
  .el-input {
    flex: 1;
    &:nth-child(1) {
      margin-right: 3%;
    }
  }
}
.btns {
  display: flex;
  .el-button {
    width: 92px;
    height: 36px;
  }
}
</style>
