<template>
  <div class="curecharts"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.curecharts');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${item.value}%<br/>`);
            });
            return str;
          }
        },
        grid: {
          top: '5%',
          right: '3%',
          left: '3%',
          bottom: '20%'
        },
        legend: {
          data: ['可用指数', '质量指数', '表现指数', '设备效率'],
          bottom: '1%',
          left: '1%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.ChartsData.xAxisValues
        },
        yAxis: {
          type: 'value',
          max: 100,
          name: '百分比%'
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            name: '可用指数',
            type: 'line',
            data: this.ChartsData.availability,
            smooth: true,
            itemStyle: {
              color: '#438edb',
              borderColor: '#fff'
            }
          },
          {
            name: '质量指数',
            type: 'line',
            data: this.ChartsData.quality,
            smooth: true,
            itemStyle: {
              color: '#19a572',
              borderColor: '#fff'
            }
          },
          {
            name: '表现指数',
            type: 'line',
            data: this.ChartsData.performance,
            smooth: true,
            itemStyle: {
              color: '#f39800',
              borderColor: '#fff'
            }
          },
          {
            name: '设备效率',
            type: 'line',
            data: this.ChartsData.oee,
            smooth: true,
            itemStyle: {
              color: '#5a5a5a',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.curecharts {
  height: 600px;
}
</style>
