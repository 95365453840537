<template>
  <div class="historgam"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.historgam');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${item.value}%<br/>`);
            });
            return str;
          }
        },
        grid: {
          top: '5%',
          right: '3%',
          left: '3%',
          bottom: '20%'
        },
        legend: {
          data: ['可用指数', '质量指数', '表现指数', '设备效率'],
          bottom: '1%',
          left: '5%',
          itemWidth: 13,
          itemHeight: 13,
          itemGap: 30
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: this.ChartsData.xAxisValues
            // axisLabel: {
            //   interval: 1
            // }
          }
        ],
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: 100,
            name: '百分比%'
          }
        ],
        series: [
          {
            name: '可用指数',
            type: 'bar',
            barGap: '10%',
            emphasis: {
              focus: 'series'
            },
            barWidth: '18px',
            data: this.ChartsData.availability,
            itemStyle: {
              color: '#438edb',
              borderColor: '#fff'
            }
          },
          {
            name: '质量指数',
            type: 'bar',
            barGap: '10%',
            emphasis: {
              focus: 'series'
            },
            data: this.ChartsData.quality,
            barWidth: '18px',
            itemStyle: {
              color: '#19a572',
              borderColor: '#fff'
            }
          },
          {
            name: '表现指数',
            type: 'bar',
            barGap: '10%',
            emphasis: {
              focus: 'series'
            },
            data: this.ChartsData.performance,
            barWidth: '18px',
            itemStyle: {
              color: '#f39800',
              borderColor: '#fff'
            }
          },
          {
            name: '设备效率',
            barGap: '10%',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: this.ChartsData.oee,
            barWidth: '18px',
            itemStyle: {
              color: '#5a5a5a',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.historgam {
  height: 600px;
}
</style>
